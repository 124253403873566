<template>
  <div>
    <b-form-group
      id="input-group-1"
      label="Tên hành động"
      label-for="input-1"
      class="required"
    >
      <validation-provider
        v-slot="{ errors }"
        key="tenHanhDong"
        :rules="{ required: true }"
        name="tenHanhDong"
      >
        <b-form-input
          id="input-1"
          ref="tenHanhDong"
          v-model="dataForm.tenHanhDong"
          v-trim-input
          type="text"
          placeholder="Nhập tên hành động"
          required
          :autofocus="dataForm.id ? false : true"
        />
        <div class="label-noti-validate">
          {{ errors[0] }}
        </div>
        <div class="label-noti-validate">
          {{ errorTrungMa }}
        </div>
      </validation-provider>
    </b-form-group>
    <b-form-group
      id="input-group-1"
      label="Số thứ tự"
      label-for="input-1"
    >
      <b-form-input
        id="input-1"
        v-model.number="dataForm.soThuTu"
        type="number"
        :min="0"
        placeholder="Nhập số thứ tự"
        required
        @input="notAllowMin"
      />
    </b-form-group>
    <div class="custom-grid-xl-container">
      <b-form-group
        id="input-group-1"
        label="Trạng thái"
        label-for="input-1"
        class="grap-6"
      >
        <b-form-checkbox
          v-model="dataForm.status"
          switch
        />
      </b-form-group>
    </div>
    <b-form-group
      id="input-group-1"
      label="Kiểu"
      label-for="input-1"
    >
      <b-form-input
        id="input-1"
        v-model="dataForm.type"
        v-trim-input
        placeholder="Nhập kiểu"
      />
    </b-form-group>
    <div class="custom-grid-xl-container">
      <b-form-group
        id="input-group-1"
        label="Xác thực ký số"
        label-for="input-1"
        class="grap-6"
      >
        <b-form-checkbox
          v-model="dataForm.xacThucKySo"
          switch
        />
      </b-form-group>
    </div>
  </div>
</template>
<script>
import {
  BFormInput,
  BFormGroup,
  BFormCheckbox,
} from 'bootstrap-vue'

import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'

extend('required', {
  ...required,
  message: 'Vui lòng nhập thông tin',
})

export default {
  components: {
    BFormInput,
    BFormGroup,
    BFormCheckbox,
  },
  props: {
    dataForm: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      errorTrungMa: null,
    }
  },
  methods: {
    notAllowMin() {
      if (this.dataForm.soThuTu < 0) {
        this.dataForm.soThuTu = 0
      }
    },
  },
}
</script>
