<template>
  <b-card>
    <action-btn
      :selected-number="selectedNumber"
      :actions-permission="[checkQuyenThemMoi, checkQuyenXoa]"
      @add-item="addCommom"
      @delete-item="deleteCommom"
      @refresh-page="refreshPage"
    />
    <good-table
      ref="vgTable"
      class="mt-1"
      :class="checkAllQuyen ? '' : 'fixed-column'"
      :columns="getColumns"
      :rows="rows"
      :total="total"
      :is-loading="isLoading"
      @column-filter="columnFilter"
      @selected-item="selectedItem"
      @page-change="pageChange"
    >
      <template
        slot="custom-filter"
        slot-scope="props"
      >
        <treeselect
          v-if="props.props.column.field === 'status'"
          v-model="payload.status"
          v-format-v-select
          class="vtreeselect-chooser"
          :options="optionsTrangThai"
          no-options-text="Không có dữ liệu"
          no-results-text="Không có dữ liệu"
          placeholder="Chọn trạng thái"
          :clear-on-select="true"
          :before-clear-all="clearTreeSelect"
          @select="nextTickTreeSelect()"
        />
      </template>
      <div
        slot="actions"
        slot-scope="{ props }"
      >
        <edit-icon
          v-if="checkQuyenSua"
          v-b-tooltip.hover.v-secondary
          title="Cập nhật"
          size="16"
          class="custom-class cursor-pointer mr-1"
          @click="updateCommom(props.row)"
        />
        <trash-2-icon
          v-if="checkQuyenXoa"
          v-b-tooltip.hover.v-secondary
          title="Xóa"
          size="16"
          class="custom-class cursor-pointer"
          @click="deleteCommomTable(props.row)"
        />
      </div>
    </good-table>
    <common-modal
      ref="commonModal"
      :title="title"
      :size="size"
      @handle-ok="handleOk"
      @handle-focus="handleFocusError"
    >
      <component
        :is="componentName === 'XoaForm' ? '' : componentName"
        ref="componentName"
        :data-form="dataForm"
      />
      <div
        v-if="isShow"
        slot="footer"
      >
        <b-button
          variant="primary"
          size="sm"
          class="mr-1"
          @click="$refs.commonModal.checkValidate()"
        >
          Lưu
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="openModal"
        >
          Đóng
        </b-button>
      </div>
    </common-modal>
    <common-modal
      ref="commonnModal"
      title="Xác nhận"
      size="sm"
    >
      Bạn có xác nhận đóng quá trình nhập liệu này không?
      <div
        v-if="isShowFooter"
        slot="footer"
      >
        <b-button
          variant="primary"
          size="sm"
          class="mr-1"
          @click="$refs.commonModal.hideModal(),$refs.commonnModal.hideModal()"
        >
          Đồng ý
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="$refs.commonnModal.hideModal()"
        >
          Đóng
        </b-button>
      </div>
    </common-modal>
  </b-card>
</template>
<script>
import {
  BCard,
  VBTooltip,
  BButton,
} from 'bootstrap-vue'
import addCssSemantic from '@/mixins/mixins'
import ActionBtn from '@/modules/mot-cua/components/common/ActionBtn.vue'
import CommonModal from '@/modules/mot-cua/components/modal/CommonModal.vue'
import GoodTable from '@/components/GoodTable.vue'
import END_POINTS from '@/api/endpoints'
import HanhDongForm from '@/modules/mot-cua/components/form/HanhDongForm.vue'
import XoaForm from '@/modules/mot-cua/components/form/XoaForm.vue'
import { removeDiacritical, checkStatus, compareObjects } from '@/utils/index'
import { EditIcon, Trash2Icon } from 'vue-feather-icons'
import { STATUS } from '@/modules/danh-muc/constants/constants'
import { PERMISSION_NAME } from '@/constants/constants'
import { hasPermission } from '@/utils/permission-utils'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BButton,
    ActionBtn,
    CommonModal,
    GoodTable,
    HanhDongForm,
    XoaForm,
    EditIcon,
    Trash2Icon,
  },
  mixins: [addCssSemantic],
  data() {
    return {
      title: 'Thêm mới phân quyền báo cáo - đơn vị',
      isShow: false,
      isShowFooter: false,
      optionsTrangThai: STATUS,
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
          sortable: false,
        },
        {
          label: 'Tên hành động',
          field: 'tenHanhDong',
          thClass: 'text-center',
          width: 'auto',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Số thứ tự',
          field: 'soThuTu',
          thClass: 'text-center',
          tdClass: 'text-right',
          width: '150px',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Trạng thái',
          field: 'status',
          width: '180px',
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'Ngày tạo',
          field: 'created',
          type: 'date',
          dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSSSSSSS',
          dateOutputFormat: 'dd/MM/yyyy HH:mm',
          thClass: 'text-center',
          tdClass: 'text-center',
          width: '200px',
        },
        {
          label: 'Thao tác',
          field: 'actions',
          thClass: 'text-center',
          tdClass: 'text-center vertical-align-middle',
          width: '100px',
        },
      ],
      rows: [],
      selectedItems: [],
      bcCbx: [],
      dvCbx: [],
      componentName: null,
      total: 0,
      selectedNumber: 0,
      payload: {
        tenHanhDong: null,
        soThuTu: null,
        // tuyChonLienThong: true,
        // status: true,
        pageNumber: 1,
        pageSize: 10,
      },
      dataForm: {
        tenHanhDong: null,
        soThuTu: 0,
        tuyChonLienThong: false,
        status: true,
        type: null,
        xacThucKySo: true,
      },
      beginObject: {},
      isLoading: false,
      size: null,
    }
  },
  computed: {
    checkQuyenThemMoi() {
      return hasPermission([PERMISSION_NAME.HANH_DONG.THEM_MOI])
    },
    checkQuyenSua() {
      return hasPermission([PERMISSION_NAME.HANH_DONG.SUA])
    },
    checkQuyenXoa() {
      return hasPermission([PERMISSION_NAME.HANH_DONG.XOA])
    },
    checkAllQuyen() {
      return [this.checkQuyenSua, this.checkQuyenXoa].every(item => item === false)
    },
    getColumns() {
      return this.checkAllQuyen ? this.columns.filter(item => item.field !== 'actions') : this.columns
    },
  },
  created() {
    this.getDataHanhDong()
  },
  methods: {
    openModal() {
      if (compareObjects(this.beginObject, this.dataForm)) {
        this.$refs.commonModal.hideModal()
        this.$refs.commonnModal.hideModal()
      } else {
        this.$refs.commonnModal.showModal()
        this.isShowFooter = true
      }
    },
    addCommom() {
      this.isShow = true
      this.title = 'Thêm mới quản lý hành động'
      this.componentName = 'HanhDongForm'
      this.size = 'lg'
      this.dataForm = {
        tenHanhDong: null,
        soThuTu: 0,
        tuyChonLienThong: false,
        status: true,
        type: null,
        xacThucKySo: true,
      }
      this.$refs.commonModal.showModal()
    },
    selectedItem(data) {
      this.selectedItems = data
      this.selectedNumber = data.length
    },
    getDataHanhDong() {
      this.isLoading = false
      try {
        this.$axios.post(END_POINTS.HANH_DONG.DS, this.payload, false).then(res => {
          if (res.data?.succeeded) {
            this.rows = res.data.data
            this.total = res.data.totalCount
          }
        })
      } finally {
        setTimeout(() => {
          this.isLoading = true
        }, 2000)
      }
    },
    resetFilter() {
      this.payload = {
        tenHanhDong: null,
        soThuTu: null,
        pageNumber: 1,
        pageSize: 10,
      }
    },
    clearTreeSelect() {
      setTimeout(() => {
        this.nextTickTreeSelect()
      }, 200)
      return true
    },
    nextTickTreeSelect() {
      this.$nextTick(() => {
        this.getDataHanhDong()
      })
    },
    refreshPage() {
      this.$refs.vgTable.$refs.vbTables.reset()
      this.resetFilter()
      this.getDataHanhDong()
    },
    handleOk() {
      if (this.componentName === 'XoaForm') {
        const payload = {
          listId: this.selectedItems.map(item => item.id),
        }
        this.$axios.delete(END_POINTS.HANH_DONG.XOA, payload).then(res => {
          if (res.data?.succeeded) {
            if (this.selectedItems.length >= this.rows.length && this.payload.pageNumber > 1) {
              this.payload.pageNumber -= 1
              this.$refs.vgTable.resetCurrentPage(this.payload.pageNumber)
            }
            this.getDataHanhDong()
            this.$refs.commonModal.hideModal()
          }
        })
      } else if (this.componentName === 'HanhDongForm') {
        if (this.dataForm?.id) {
          this.$axios.put(END_POINTS.HANH_DONG.SUA, this.$trimObject(this.dataForm), false).then(res => {
            if (checkStatus(res)) {
              this.getDataHanhDong()
              this.$refs.commonModal.hideModal()
            } else {
              this.$refs.componentName.errorTrungMa = res.data.message
            }
          })
        } else {
          this.$axios.post(END_POINTS.HANH_DONG.THEM, this.$trimObject(this.dataForm), false).then(res => {
            if (checkStatus(res)) {
              this.getDataHanhDong()
              this.$refs.commonModal.hideModal()
            } else {
              this.$refs.componentName.errorTrungMa = res.data.message
            }
          })
        }
      }
    },
    updateCommom(data) {
      this.isShow = true
      this.title = 'Cập nhật quản lý hành động'
      this.componentName = 'HanhDongForm'
      this.size = 'lg'
      this.dataForm = {
        id: data.id,
        tenHanhDong: data.tenHanhDong,
        soThuTu: data.soThuTu,
        tuyChonLienThong: data.tuyChonLienThong,
        status: data.status,
        type: data.type,
        xacThucKySo: data.xacThucKySo,
      }
      this.$nextTick(() => {
        this.beginObject = _cloneDeep(this.dataForm)
        this.$refs.commonModal.showModal()
      })
    },
    deleteCommomTable(data) {
      this.isShow = false
      this.title = 'Xác nhận'
      this.dataForm = 'Bạn có xác nhận xóa hành động này không?'
      this.componentName = 'XoaForm'
      this.size = 'md'
      this.selectedItems = [data]
      this.$refs.commonModal.showModal()
    },
    deleteCommom() {
      this.isShow = false
      this.title = 'Xác nhận'
      this.dataForm = 'Bạn có xác nhận xóa hành động này không?'
      this.componentName = 'XoaForm'
      this.size = 'md'
      this.$refs.commonModal.showModal()
    },
    pageChange() {
      const data = this.$refs.vgTable.getCurrentPage()
      this.payload = {
        ...this.payload,
        pageNumber: data.pageNumber,
        pageSize: data.pageSize,
      }
      this.$axios.post(END_POINTS.HANH_DONG.DS, this.payload, false).then(res => {
        if (res.data?.succeeded) {
          this.rows = res.data.data
        }
      })
    },
    columnFilter(data) {
      this.payload = {
        tenHanhDong: data.tenHanhDong ? data.tenHanhDong.replace(/\s+/g, ' ').trim() : '',
        soThuTu: data.soThuTu ? data.soThuTu.replace(/\s+/g, ' ').trim() : '',
        tuyChonLienThong: data.tuyChonLienThong,
        status: this.payload.status,
        pageNumber: 1,
        pageSize: this.payload.pageSize,
      }
      this.payload = {
        ...data,
        status: this.payload.status,
        pageNumber: 1,
        pageSize: this.payload.pageSize,
      }
      this.$axios.post(END_POINTS.HANH_DONG.DS, this.payload, false).then(res => {
        if (res.data?.succeeded) {
          this.rows = res.data.data
          this.total = res.data.totalCount
          this.$refs.vgTable.resetCurrentPage(1)
        }
      })
    },
    columnFilterTable() {
      this.$nextTick(() => {
        this.$refs.vgTable.onColumnFilter()
      })
    },
    onFilterSelect(options, search) {
      return options.filter(item => removeDiacritical(item.name).indexOf(removeDiacritical(search.trim())) >= 0)
    },
    handleFocusError(first) {
      if (first) {
        const field = first[0]
        if (['nhomMauPhieu'].includes(field)) {
          this.$refs.componentName.$refs[field].$el.querySelector('input').focus()
        } else {
          this.$refs.componentName.$refs[field].focus()
        }
      }
    },
  },
}
</script>
