<template>
  <hanh-dong />
</template>

<script>
import HanhDong from '@/modules/mot-cua/components/pages/HanhDong.vue'

export default {
  name: 'TrangChu',
  components: {
    HanhDong,
  },
}
</script>

<style scoped></style>
